import React from "react";
import loadable from "@loadable/component";
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image"
import './assets/styles/_index.scss';
import ScrollAnimation from "react-animate-on-scroll";
import GGFXImage from "../../modules/GGFXImage";
import useDeviceMedia from "../../hooks/useDeviceMedia";
import useDeviceWidth from "../../hooks/useDeviceWidth";
const { PreviewCTALink } = require("@starberry/gatsby-theme-utils/Modules/PreviewCTALinkModule")
const { ContentModule } = require("@starberry/gatsby-theme-utils/Modules/ContentModule")
const { ImageModule } = require("@starberry/gatsby-theme-utils/Modules/ImageModule")


const EventBanner = (props) => {
    var imagename = "event.banner_section_banner_image.landing_banner_image";
    var mobileImageName = "event.mobile_image.mobile_event"

    const deviceWidth = useDeviceWidth()

    return (
        <section className={`event-banner ${props.tag === "landing" ? "inner-page" : ""}`}>
            
          {deviceWidth<768&&props.mobile_image?.url ?
            <GGFXImage
                ImageSrc={props?.mobile_image}
                altText={`${props?.mobile_image?.alternativeText ? props?.mobile_image?.alternativeText 
                : props.banner_title ? props.banner_title : props.title} banner`} 
                imagetransforms={props?.ggfx_results}
                renderer="bgImg"
                imagename={mobileImageName}
                strapiID={props?.id}
                className="banner-img d-block d-md-none"
            />
            :

            <GGFXImage
                ImageSrc={props?.image}
                altText={`${props?.image?.alternativeText ? props?.image?.alternativeText 
                : props.banner_title ? props.banner_title : props.title} banner`} 
                imagetransforms={props?.ggfx_results}
                renderer="bgImg"
                imagename={imagename}
                strapiID={props?.id}
                className="banner-img"
            />
          }

            <div className="overlay-bg"></div>
            <ScrollAnimation animateIn="animate__slideInUp" animateOnce delay={100} offset={100}>
            <Container className="banner-search-container">
                <Row>
                    <Col lg={12}>

                        {props.banner_title && <h1>{props.banner_title}</h1>}

                        {(props.banner_content?.data?.banner_content || (props.isPreviewEnabled && props.banner_content)) &&
                         <ContentModule Content={props.banner_content?.data?.banner_content || props.banner_content} />}
                        <ul className="list-inline">
                            {props.cta_1_title && 
                                <li className="list-inline-item">
                                    <a onClick={() => {props?.handleModal(); localStorage.setItem("eventform", "true")}} class="button button-filled-green"  >{props.cta_1_title}</a>
                                </li>
                            }
                            {/* {props.cta_2_title && 
                                <li className="list-inline-item">
                                    <Link onClick={props?.handleModal} class="button button-filled-green"  >{props.cta_2_title}</Link>
                                </li>
                            } */}
                        </ul>
                       
                    </Col>
                </Row>
            </Container>
            </ScrollAnimation>
        </section>
    )
}

export default EventBanner